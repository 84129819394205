import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { QuizModel } from "@/models";
import { Common } from "../Common";
import { ErrorCode, ErrorMessage } from "@/constant";
import { Lightbox } from "@/components";

export class QuizForm {
  sqid = '';
  name = '';
  student = '';
  city = '';
  school = '';
  class = '';
  grade = '';
  unit = '';
  level = '';
  question = [];
  total = 0;
  accuracy = 0;
  completeDate = '';
  active = '';
  token = window.localStorage.getItem('adminToken') as string;
}

type response = { [key: string]: unknown };

@Component<AdminTestResultInfoController>({
  components: {
    SideNav, Lightbox
  }
})
export default class AdminTestResultInfoController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private quizForm = new QuizForm();
  private token = window.localStorage.getItem('adminToken') as string;
  private errorMsg = "";
  private openLB = false;
  private errNo = 0;

  public async created() {
    const item: { [key: string]: string } = {
      sqid: this.$route.query.sqid as string,
      token: this.token,
    }
    const editData = await QuizModel.getResult(item) as response;
    this.assignDataToForm(editData);
  }

  private assignDataToForm(data: response) {
    Common.checkToken(data.ERR_CODE as number);

    const item = data.info as {
      sqid: string;
      name: string;
      student: string;
      city: string;
      school: string;
      class: string;
      grade: string;
      unit: string;
      level: string;
      completeDate: string;
      question: [];
      total: number;
      accuracy: number;
      active: string;
    };
    this.quizForm.sqid = item.sqid;
    this.quizForm.name = item.name;
    this.quizForm.student = item.student;
    this.quizForm.city = item.city;
    this.quizForm.school = item.school;
    this.quizForm.class = item.class;
    this.quizForm.grade = item.grade;
    this.quizForm.unit = item.unit;
    this.quizForm.level = item.level;
    this.quizForm.completeDate = item.completeDate;
    this.quizForm.question = item.question;
    this.quizForm.total = item.total;
    this.quizForm.accuracy = item.accuracy;
    this.quizForm.active = item.active;
  }

  private async validateAndSubmit() {
    if (!this.doubleClick) {
      this.doubleClick = true;

      const item: { token: string, sqid: string, teacherVerify: response } = {
        token: this.token,
        sqid: this.quizForm.sqid,
        teacherVerify: {},
      }

      for (const [key, option] of Object.entries(this.quizForm.question)) {
        if(option['type'] == 3) {
          item.teacherVerify[key] = {
            qqid: option['qqid'],
            verify: option['verify'],  
          }
        }
      }

      this.errNo = await QuizModel.verify(item);
      Common.checkToken(this.errNo);
      this.openLB = true;
      this.doubleClick = false;
      this.errorMsg = ErrorMessage[this.errNo];
    }
  }

  private closeLB() {
    this.openLB = false;
    if (this.errNo === ErrorCode.Success) {
      this.$router.push("/admin/quiz/result");
    }
  }
}
